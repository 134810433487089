<template>
  <!-- end header -->
        <br style="margin-top: 40px;">
        <!-- subscriptions -->
        <section class="section">
            <div class="container">
                <div class="row">
                </div>
            </div>
        </section>
        <!-- end subscriptions -->

        <!-- catalog -->
        <div class="catalog2 catalog--page" style="padding-top: calc(-90% + 45vh);"> 
            <div class="container">
                <div class="row">
                    <div class="col-12">


<aside class="profile-card" v-for="publishs in SeriesInfo" :key="publishs.id">

  <header>

    <!-- here’s the avatar -->
    <a href="#">
      <img :src="publishs.series_img">
    </a>

    <!-- the username -->
    <h1>{{ publishs.series_name_ar }}</h1>

    <!-- and role or location -->
    <!-- <h2>- {{ publish.ser }} -</h2> -->

  </header>


</aside>



                    </div>
                    <div class="col-12">
                        <div class="row row--grid">
                            <div class="col-6 col-sm-4 col-lg-3 col-xl-2" v-for="publish in SeriesContents" :key="publish.id">

                                <div class="cardBox">
                                <div class="card">
                                <a @click="getFeeds(publish)" class="card__cover" style="height: 230px;">
                                        <img :src="publish.img" alt="">
                                        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M11 1C16.5228 1 21 5.47716 21 11C21 16.5228 16.5228 21 11 21C5.47716 21 1 16.5228 1 11C1 5.47716 5.47716 1 11 1Z" stroke-linecap="round" stroke-linejoin="round"/><path fill-rule="evenodd" clip-rule="evenodd" d="M14.0501 11.4669C13.3211 12.2529 11.3371 13.5829 10.3221 14.0099C10.1601 14.0779 9.74711 14.2219 9.65811 14.2239C9.46911 14.2299 9.28711 14.1239 9.19911 13.9539C9.16511 13.8879 9.06511 13.4569 9.03311 13.2649C8.93811 12.6809 8.88911 11.7739 8.89011 10.8619C8.88911 9.90489 8.94211 8.95489 9.04811 8.37689C9.07611 8.22089 9.15811 7.86189 9.18211 7.80389C9.22711 7.69589 9.30911 7.61089 9.40811 7.55789C9.48411 7.51689 9.57111 7.49489 9.65811 7.49789C9.74711 7.49989 10.1091 7.62689 10.2331 7.67589C11.2111 8.05589 13.2801 9.43389 14.0401 10.2439C14.1081 10.3169 14.2951 10.5129 14.3261 10.5529C14.3971 10.6429 14.4321 10.7519 14.4321 10.8619C14.4321 10.9639 14.4011 11.0679 14.3371 11.1549C14.3041 11.1999 14.1131 11.3999 14.0501 11.4669Z" stroke-linecap="round" stroke-linejoin="round"/></svg>
                                    </a>
                                    <h3 class="card__title" style="padding-bottom: 9px;margin-bottom: 7px;"><a @click="getFeeds(publish)">{{ publish.title_ar }}</a></h3>
                                    <ul class="card__list">
                                        <li style="margin-left: 0;">{{ publish.cp_name }}</li>
                                        <li>--</li>
                                        <li style="margin-left: 0;">{{ publish.series_name_ar }}</li>
                                        
                                </ul>
                        </div>
                    </div>
                                
                            </div>
                        </div>
                    </div>
                </div>		
                
            </div>

        </div>
        <!-- end catalog -->
</template>

<script>
import axios from "axios";
import { ref } from 'vue';
import config from '@/Api/config';
import { useRouter } from "vue-router"
import { useCookie } from 'vue-cookie-next'
export default {
   name: 'Series',
   async setup() {
    const router = useRouter();
    const cookie = useCookie();
    const SeriesContents = ref([]);
    const SeriesInfo = ref([]);
    cookie.setCookie("product_code","9347514349");


    try {
      await axios.get(`GetAllSeriesContent.php?ID=`+cookie.getCookie("series_id"), {
                    headers: { 'Authorization': config.authHeader, },
                }).then((res) => {
        SeriesContents.value = res.data.SeriesContent; 
      });
    } catch (err) {
      console.log(err);
    }


    try {
      await axios.get(`GetSeriesInfo.php?ID=`+ cookie.getCookie("series_id"), {
                    headers: { 'Authorization': config.authHeader, },
                }).then((res) => {
        SeriesInfo.value = res.data.SeriesInfo; 
      });
    } catch (err) {
      console.log(err);
    }
    const getFeeds = (publish) => {
      cookie.setCookie('cat_id', publish.cat_id);
      if(cookie.isCookieAvailable("mdn") && cookie.isCookieAvailable("status")) {
        axios.get(
          "https://nashoof.sd.zain.com/DSPAPIs/APIs/DSPCheckLogin.php?msisdn=" + cookie.getCookie("mdn") 
            , {
                headers: {
                    'Authorization': config.authHeader,
                },
            }).then((res) => {
              if (res.data.remming_minutes > 0) {
                axios.get('AddUserInteraction.php?username='+ cookie.getCookie("mdn")+'&content_id='+cookie.getCookie("Content_id")+'&interaction_type=view', {
                    headers: { 'Authorization': config.authHeader, 
                },
                }).then((response) => {
                    if (response.data.error_code == 0) {
                        console.log("Add Interaction Success");
                        cookie.setCookie('Content_id', publish.id);
                        router.push("/Content");
                    } else {
                        console.log("Add Interaction failed");
                        cookie.setCookie('Content_id', publish.id);
                        router.push("/Content");
                    }
                });
              } else {
                cookie.setCookie('Content_id', publish.id);

                router.push("/Trailer");

                // router.push("/Content");

              }
          });
      } else {
          cookie.setCookie('id', publish.id);
          cookie.setCookie('Content_id', publish.id);

          // router.push({ name: "Landing" });
          router.push("/Trailer");  
          // cookie.setCookie('Content_id', publish.id);

          // router.push("/Content");
            }
    };
      return { SeriesContents,SeriesInfo, getFeeds };
  },
}
</script>

<style scoped>
.cardBox {
  width: auto;
height: 26vh;
position: relative;
display: grid;
place-items: center;
overflow: hidden;
border-radius: 20px;
box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 10px 0px, rgba(0, 0, 0, 0.5) 0px 2px 15px 0px;
margin-bottom: 1vh;
margin-top: 1vh;
}
.card {
  position: absolute;
  width: 95%;
  height: 95%;
  background: #0a888e;
  border-radius: 20px;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  color: #ffffff;
  overflow: hidden;
  padding: 0;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 30px 60px -12px inset,
    rgba(0, 0, 0, 0.5) 0px 18px 36px -18px inset;
    margin-top: 0;
}
.card h2, span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 3rem;
  font-weight: 800;
  pointer-events: none;
  opacity: 0.1;
}

span {
  top: 75%;
}

.card .content h3 {
  font-size: 3rem;
  padding-bottom: 10px;
}
.card .content p {
  font-size: 1.6rem;
  line-height: 25px;
}
.card .content {
  transform: translateY(100%);
  opacity: 0;
  transition: 0.3s ease-in-out;
}

.card:hover .content {
  transform: translateY(0);
  opacity: 1;
}

.cardBox::before {
  content: "";
  position: absolute;
  width: 40%;
  height: 150%;
  background: #40E0D0;
background: -webkit-linear-gradient(to right, #029797, #42e8f4, #40e0e0);
background: linear-gradient(to right, #029797, #42e8f4, #40e0e0);
  animation: glowing01 200s linear infinite;
  transform-origin: center;
  animation: glowing 200s linear infinite;
}

@keyframes glowing {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}


.title {
    font-family: 'Cairo', sans-serif;
    font-weight: 500;
    font-size: 18px;
    color: #fff;
    margin-top: 10px;
    margin-bottom: 5px;
    transition: 0.5s;
    display: block;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    text-align: start;
    overflow: hidden;
    white-space: nowrap;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    word-wrap: break-word;
    background-color: #047072;
    background-image: linear-gradient(0deg,#028d97 0,#71C6F7 );
    padding: 8px 5px 5px 5px;
    border-radius: 12px;


}
.catalog2 {
    position: relative;
    padding: 5px 0 25px;
    padding-top: calc(50vh - 90%);
    padding-right: 0px;
    padding-bottom: 25px;
    padding-left: 0px;
    overflow: hidden;
    padding-bottom: calc(50vh - 60%);
}
.catalog__paginator-wrap {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 40px auto 0;
    width: 100%;
    height: 50px;
    border-radius: 16px;
    background-color: #151f30;
    background-color: #047072;
    background-image: linear-gradient(62deg, #047072 0%, #00c9c9 100%);
}








header img {
  margin: 0 auto;
display: block;
width: 100%;
border: 5px solid #fff;
height: 26vh;
}

aside {
  border-top: 0px solid #26A69A;
border-bottom: 0px solid #00695C;
border-radius: 2%;
margin: 0 auto;
display: block;
height: 35vh;
width: 100%;
background: linear-gradient(0deg,#029797 0,#71C6F7 );
  background-size: auto;
background-size: cover;
overflow: hidden;
/* box-shadow: 0 0 100px 10px #fff; */
transition: all ease 0.3s;
box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 10px 0px, rgba(0, 0, 0, 0.26) 0px 2px 15px 0px;
border: 12px solid #fff;
}

aside:hover {
  border-top: 4px solid #26A69A;
  border-bottom: 4px solid #00695C;
  border-radius: 5px;
  height: 35vh;
  width: 100%;
  box-shadow: 0 0 70px 10px #fff;
}

aside:hover header img {
  animation: profile_image 2000ms linear both;
  animation-delay: 0.5s;
}

header {
  text-align: center;
}

header h1 {
  position: relative;
  text-align: center;
  color: #fff;
  text-shadow: 1px 1px rgba(0, 0, 0, 0.5);
  font-size: 25px;
  line-height: 25px;
  display: inline-block;
  padding: 10px;
  transition: all ease 0.250s;
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
}

aside:hover header h1 {
  margin-top: 0px;
  outline: 0 solid #fff;
  border-top: 0px solid #fff;
  border-bottom: 1px solid #fff;
}

header h2 {
  text-align: center;
  color: #fff;
  text-shadow: 1px 1px rgba(0, 0, 0, 0.5);
  font-size: 17px;
  font-weight: normal;
  line-height: 0px;
  margin: 0;
}

.profile-bio {
  margin-top: 20px;
  padding: 1px 20px 10px 20px !important;
  transition: all linear 1.5s;
  color: #fff;
  font-size: 14px;
  opacity: 0;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.42) 49%, rgba(0, 0, 0, 0.61) 100%);
}

aside:hover .profile-bio {
  opacity: 1;
}

.profile-bio p:first-child {
  text-align: center;
  font-size: 16px;
}

.profile-social-links {
  position: relative;
  margin-top: -440px;
  margin-left: -100px;
  list-style-type: none;
  opacity: 0;
  transition: all ease 0.5s;
}

aside:hover .profile-social-links {
  margin-left: -30px;
  opacity: 1;
}

.profile-social-links li img {
  width: 30px;
  background: #fff;
  border-radius: 50%;
  padding: 5px;
}
/*PROFILE IMAGE ANIMATE */

@keyframes profile_image {
  0% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  3.4% {
    transform: matrix3d(1.032, 0, 0, 0, 0, 1.041, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  4.7% {
    transform: matrix3d(1.045, 0, 0, 0, 0, 1.06, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  6.81% {
    transform: matrix3d(1.066, 0, 0, 0, 0, 1.089, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  9.41% {
    transform: matrix3d(1.088, 0, 0, 0, 0, 1.117, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  10.21% {
    transform: matrix3d(1.094, 0, 0, 0, 0, 1.123, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  13.61% {
    transform: matrix3d(1.112, 0, 0, 0, 0, 1.133, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  14.11% {
    transform: matrix3d(1.114, 0, 0, 0, 0, 1.133, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  17.52% {
    transform: matrix3d(1.121, 0, 0, 0, 0, 1.124, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  18.72% {
    transform: matrix3d(1.121, 0, 0, 0, 0, 1.119, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  21.32% {
    transform: matrix3d(1.12, 0, 0, 0, 0, 1.107, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  24.32% {
    transform: matrix3d(1.115, 0, 0, 0, 0, 1.096, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  25.23% {
    transform: matrix3d(1.113, 0, 0, 0, 0, 1.094, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  29.03% {
    transform: matrix3d(1.106, 0, 0, 0, 0, 1.09, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  29.93% {
    transform: matrix3d(1.105, 0, 0, 0, 0, 1.09, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  35.54% {
    transform: matrix3d(1.098, 0, 0, 0, 0, 1.096, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  36.74% {
    transform: matrix3d(1.097, 0, 0, 0, 0, 1.098, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  41.04% {
    transform: matrix3d(1.096, 0, 0, 0, 0, 1.102, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  44.44% {
    transform: matrix3d(1.097, 0, 0, 0, 0, 1.103, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  52.15% {
    transform: matrix3d(1.099, 0, 0, 0, 0, 1.101, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  59.86% {
    transform: matrix3d(1.101, 0, 0, 0, 0, 1.099, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  63.26% {
    transform: matrix3d(1.101, 0, 0, 0, 0, 1.099, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  75.28% {
    transform: matrix3d(1.1, 0, 0, 0, 0, 1.1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  85.49% {
    transform: matrix3d(1.1, 0, 0, 0, 0, 1.1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  90.69% {
    transform: matrix3d(1.1, 0, 0, 0, 0, 1.1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  100% {
    transform: matrix3d(1.1, 0, 0, 0, 0, 1.1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
}
/*NAME ANIMATE */

aside:hover header h1 {
  animation: name_and_job 1500ms linear both;
  animation-delay: 0.4s;
}

@keyframes name_and_job {
  0% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -300, 0, 0, 1);
  }
  1.3% {
    transform: matrix3d(3.905, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -237.02, 0, 0, 1);
  }
  2.55% {
    transform: matrix3d(4.554, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -182.798, 0, 0, 1);
  }
  4.1% {
    transform: matrix3d(4.025, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -125.912, 0, 0, 1);
  }
  5.71% {
    transform: matrix3d(3.039, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -79.596, 0, 0, 1);
  }
  8.11% {
    transform: matrix3d(1.82, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -31.647, 0, 0, 1);
  }
  8.81% {
    transform: matrix3d(1.581, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -21.84, 0, 0, 1);
  }
  11.96% {
    transform: matrix3d(1.034, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 4.825, 0, 0, 1);
  }
  12.11% {
    transform: matrix3d(1.023, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 5.53, 0, 0, 1);
  }
  15.07% {
    transform: matrix3d(0.947, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 12.662, 0, 0, 1);
  }
  16.12% {
    transform: matrix3d(0.951, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 13.007, 0, 0, 1);
  }
  27.23% {
    transform: matrix3d(1.001, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 2.352, 0, 0, 1);
  }
  27.58% {
    transform: matrix3d(1.001, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 2.121, 0, 0, 1);
  }
  38.34% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -0.311, 0, 0, 1);
  }
  40.09% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -0.291, 0, 0, 1);
  }
  50% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -0.048, 0, 0, 1);
  }
  60.56% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0.007, 0, 0, 1);
  }
  82.78% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  100% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
}

aside:hover ul li:first-child {
  animation: social_animation 2000ms ease-in-out both;
  animation-delay: 0.75s;
}

aside:hover ul li:nth-child(2) {
  animation: social_animation 2000ms ease-in-out both;
  animation-delay: 1s;
}

aside:hover ul li:nth-child(3) {
  animation: social_animation 2000ms ease-in-out both;
  animation-delay: 1.25s;
}

@keyframes social_animation {
  0% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -300, 0, 0, 1);
  }
  1.3% {
    transform: matrix3d(3.905, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -237.02, 0, 0, 1);
  }
  2.55% {
    transform: matrix3d(4.554, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -182.798, 0, 0, 1);
  }
  4.1% {
    transform: matrix3d(4.025, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -125.912, 0, 0, 1);
  }
  5.71% {
    transform: matrix3d(3.039, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -79.596, 0, 0, 1);
  }
  8.11% {
    transform: matrix3d(1.82, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -31.647, 0, 0, 1);
  }
  8.81% {
    transform: matrix3d(1.581, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -21.84, 0, 0, 1);
  }
  11.96% {
    transform: matrix3d(1.034, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 4.825, 0, 0, 1);
  }
  12.11% {
    transform: matrix3d(1.023, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 5.53, 0, 0, 1);
  }
  15.07% {
    transform: matrix3d(0.947, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 12.662, 0, 0, 1);
  }
  16.12% {
    transform: matrix3d(0.951, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 13.007, 0, 0, 1);
  }
  27.23% {
    transform: matrix3d(1.001, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 2.352, 0, 0, 1);
  }
  27.58% {
    transform: matrix3d(1.001, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 2.121, 0, 0, 1);
  }
  38.34% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -0.311, 0, 0, 1);
  }
  40.09% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -0.291, 0, 0, 1);
  }
  50% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -0.048, 0, 0, 1);
  }
  60.56% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0.007, 0, 0, 1);
  }
  82.78% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  100% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
}
/*.catalog__paginator li {
    margin-left: 10%;
}
@media (max-width:360px){
.catalog2 {
    padding-top: 10%;
}
}
@media (max-width:320px){
.catalog2 {
    padding-top: 10%;
}
}
@media (max-width:768px){
.catalog2 {
    padding-top: 10%;
}
} */
</style>